<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="https://www.hanwha-pm.com/upload/product/20210526/105059mppb.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h1 class="font-size-20 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
                    The HM520 series models are a state-of-the-art high-speed
                    modular with extreme flexibility.
                  </h2>
                  <br />
                  <ul class="container">
                    <li>
                      <h5>
                        Actual productivity is highest among machines of the
                        same class
                      </h5>
                    </li>
                    <li>
                      <h5>Optimized to high quality production</h5>
                    </li>
                    <li>
                      <h5>
                        Unmanned, non-stop and zero defect using the Smart
                        Factory S/W Solution
                      </h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br /><br /><br />
            <h1><strong> Specifications </strong></h1>
            <br /><br />
            <div class="row container" style="font-size: 18px">
              <div class="container-fluid">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row" style="width: 400px">Alignment</th>
                        <td style="font-size: 12px">Fix Camera</td>
                      </tr>
                      <tr>
                        <th scope="row">Number of Spindles</th>
                        <td style="font-size: 12px">
                          6 spindles x 2 Gantry (Piano Type)
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Placement Speed</th>
                        <td style="font-size: 12px">60,000 CPH</td>
                      </tr>
                      <tr>
                        <th scope="row">Placement Accuracy</th>
                        <td style="font-size: 12px">
                          ±40 μm @ Cpk ≥ 1.0 (Chip) / ±30 μm @ Cpk ≥ 1.0 (IC)
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Component Range</th>
                        <td style="font-size: 12px">0402 ~ □ 55 mm</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <br /><br />
                <h1><strong>Features</strong></h1>
                <br /><br />

                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_4.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> 
Multi Function </strong>
                      </h1>
                      <h3>
                        <strong>6 Spindle Head (Piano Type)</strong>
                      </h3>
                      <p>
                       Applicable to the placement of a wide range of components from small chips of standard components to odd-shape components.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_5.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> High Reliability </strong>
                      </h1>
                      <h3>
                        <strong>
                          Applies Height Sensor</strong
                        >
                      </h3>
                      <p>
                        Picks up a component using the height sensor without performing separate teaching and automatically corrects the difference in the placement height due to PCB warping.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_8.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> Flexible Production</strong>
                      </h1>
                      <h3><strong>Applicable Range of Components</strong></h3>
                      <p>
                        Possible to place components from 0402 to a maximum of 55mm components, with a height of a maximum of 15mm.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="https://www.hanwha-pm.com/en/resource/images/product/ico_features_smt_7.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> Easy Operation </strong>
                      </h1>
                      <h3>
                        <strong>Convenient Nozzle Management</strong>
                      </h3>
                      <p>
                       The fiducial camera recognizes the barcode of the ANC and nozzles to ensure optimum nozzle rearrangement on the ANC block, as well as ANC block change by one touch.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>